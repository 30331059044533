import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import WebFont from 'webfontloader';
import {
  createStore, applyMiddleware, compose, combineReducers
} from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import i18n from 'i18next';

import './index.css';
import './i18n';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import authReducer from './Auth/store/reducers/auth';
import signUpReducer from './Auth/store/reducers/signUp';
import entityFieldsReducer from './SuperUser/store/reducers/entityFields';
import newsReducer from './SuperUser/store/reducers/news';
import { watchAuth, watchSignUp } from './Auth/store/sagas/index';
import { watchEntityFields, watchNews } from './SuperUser/store/sagas/index';

if (window.self !== window.top) {
  document.body.innerHTML = '<h1>Embedding in iframes is not allowed.</h1>';
  throw new Error('Embedding in iframes is not allowed.');
}

WebFont.load({
  google: {
    families: ['Barlow:300,400,400i,500,600,700']
  }
});

const composeEnhancers = (process.env.NODE_ENV === 'development'
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) || compose;

const rootReducer = combineReducers({
  auth: authReducer,
  signUp: signUpReducer,
  entityFields: entityFieldsReducer,
  news: newsReducer
});

const sagaMiddleware = createSagaMiddleware();

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk, sagaMiddleware)));

sagaMiddleware.run(watchAuth);
sagaMiddleware.run(watchSignUp);
sagaMiddleware.run(watchEntityFields);
sagaMiddleware.run(watchNews);

const app = (
  <GoogleReCaptchaProvider reCaptchaKey="6Lf9WVMpAAAAAPMOTyJHRYwStBE_1VUAM3OclTuK" language={i18n.language?.split('-')[0]}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </GoogleReCaptchaProvider>
);

window.onload = () => {
  ReactDOM.render(app, document.getElementById('root'));
  registerServiceWorker();
};
